import { useMutation } from '@apollo/client';
import { AccessTokenGraphQLResponse } from './types';
import { GET_ACCESS_TOKEN_FOR_SAVINGS } from './mutation';

export function usePublicToken() {
  const [createNewToken, { data, error, loading }] = useMutation<AccessTokenGraphQLResponse, { id: string }>(
    GET_ACCESS_TOKEN_FOR_SAVINGS,
  );

  return { createNewToken, data, error, loading };
}
