import { gql } from '@apollo/client';

const FULL_TRADER_INFO = gql`
  fragment FullTraderInfoFields on RetailTrader {
    id
    name
    score
    documents {
      docType
      uri
    }
  }
`;

const GET_MCP_PROPOSAL_BY_ID_FOR_SAVINGS = gql`
  ${FULL_TRADER_INFO}
  query McpProposalByIdForSavings($id: ID!, $accessToken: String!) {
    mcpProposalByIdForSavings(mcpProposalId: $id, accessToken: $accessToken) {
      id
      volume
      transactionType
      energyType
      deadline
      period
      status
      submarket
      proposalCommission
      commissionType
      trader {
        id
        name
      }
      group {
        id
        name
        docNumber
        legalName
      }
      mcpBids {
        id
        trader {
          ...FullTraderInfoFields
        }
        spread
        spreadWithCommission
        bestOffer
        deadline
        tusdRefund
        paymentDeadline
        winnerAt
        totalValue
      }
    }
  }
`;

export default GET_MCP_PROPOSAL_BY_ID_FOR_SAVINGS;
