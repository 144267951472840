import numeral from 'numeral';
import { MainInfoItem } from '@components/organisms/proposal-drawer-content';
import { Bid } from '@hooks/get-mcp-proposal-by-id/types';
import { IconName } from '@components/atoms/hero-icon';

interface MainInfoProps {
  id: string;
  volume?: number;
  energyType: string;
  submarket: string;
  supply?: string;
}

export const mainInfo = (proposal: MainInfoProps): MainInfoItem => {
  return {
    id: proposal.id,
    volume: {
      title: 'Volume',
      content: `${numeral(proposal.volume).format('0,0.000')} MWh`,
      iconName: 'BoltIcon',
    },
    energyType: {
      title: 'Tipo de energia',
      content: proposal.energyType,
      iconName: 'BoltIcon',
    },
    submarket: {
      title: 'Submercado',
      content: proposal.submarket,
      iconName: 'GlobeAmericasIcon',
    },
    period: {
      title: 'Período do fornecimento',
      content: proposal.supply ? proposal.supply : '',
      iconName: 'CalendarIcon',
    },
  };
};

type NuggetKey = 'title' | 'content';

type Nugget = {
  [key in NuggetKey]: string;
} & {
  iconName: IconName;
};

interface BidInfo {
  id: string;
  finalCost: Nugget;
  energyType: Nugget;
  tusdRefund: Nugget;
  paymentDate: Nugget;
}

export const bidInfo = (bid: Bid, energyType: string): BidInfo => {
  return {
    id: bid.id,
    finalCost: {
      title: 'Custo final',
      content: numeral(bid.totalValue).format('$ 0,0.00'),
      iconName: 'CurrencyDollarIcon',
    },
    energyType: {
      title: 'Tipo de energia',
      content: energyType,
      iconName: 'CalendarIcon',
    },
    tusdRefund: {
      title: 'Ressarcimento TUSD',
      content: numeral(bid.tusdRefund).format('$ 0,0.00'),
      iconName: 'BanknotesIcon',
    },
    paymentDate: {
      title: 'Data de pagamento',
      content: `${bid.paymentDeadline}º dia útil`,
      iconName: 'CalendarIcon',
    },
  };
};

export const cardGridResponsivenessValues = (bidListLength: number) => {
  if (bidListLength === 1) return 'grid grid-cols-1 mt-8 gap-8';
  if (bidListLength === 2) return 'grid grid-cols-1 md:grid-cols-2 mt-8 gap-8';
  return 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-8 gap-8';
};
