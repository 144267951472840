// Private paths

export const HOME_PATH = '/';

export const VIEW_PROPOSAL_LIST_PATH = '/mercado-curto-prazo/cotacoes';
export const CREATE_PROPOSAL_PATH = '/mercado-curto-prazo/criar-cotacao';
export const EDIT_PROPOSAL_PATH = '/mercado-curto-prazo/editar-cotacao';
export const CREATE_BID_PATH = '/mercado-curto-prazo/criar-proposta';
export const INDICATIVE_PRICES_DASHBOARD = '/precos-indicativos/dashboard';

// Trader paths + suffixes
export const SEND_INDICATIVE_PRICES = '/precos-indicativos/enviar';

// 404
export const DOES_NOT_EXIST_PATH = '/does-not-exist';

// Public paths

export const COMPILED_MCP_PROPOSALS = '/mercado-curto-prazo/compilado';
