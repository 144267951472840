import { gql } from '@apollo/client';

const FULL_TRADER_INFO = gql`
  fragment FullTraderInfoFields on RetailTrader {
    id
    name
    score
    documents {
      docType
      uri
    }
  }
`;
const TRADER_INFO = gql`
  fragment TraderInfoFields on RetailTrader {
    id
  }
`;

const GET_MCP_PROPOSAL_BY_ID = gql`
  ${FULL_TRADER_INFO}
  query McpProposalById($id: ID!) {
    mcpProposalById(mcpProposalId: $id) {
      id
      volume
      transactionType
      energyType
      deadline
      period
      status
      submarket
      proposalCommission
      commissionType
      trader {
        id
        name
      }
      group {
        id
        name
        docNumber
        legalName
      }
      mcpBids {
        id
        trader {
          ...FullTraderInfoFields
        }
        spread
        spreadWithCommission
        bestOffer
        deadline
        tusdRefund
        paymentDeadline
        winnerAt
        totalValue
      }
    }
  }
`;

export const GET_MCP_PROPOSAL_BY_ID_TO_CREATE_BID = gql`
  ${TRADER_INFO}
  query McpProposalById($id: ID!) {
    mcpProposalById(mcpProposalId: $id) {
      id
      transactionType
      period
      deadline
      energyType
      submarket
      trader {
        id
      }
      mcpBids {
        id
        trader {
          ...TraderInfoFields
        }
        spread
        bestOffer
      }
    }
  }
`;
export default GET_MCP_PROPOSAL_BY_ID;
