import { ColumnOptions } from '@components/atoms/table';
import { BidParsed } from './parser';

export const proposalDetailTableColumn: ColumnOptions<BidParsed>[] = [
  { accessor: 'traderName', header: 'Fornecedora', sortableColumn: false, noWrap: true },
  { accessor: 'spreadWithCommission', header: 'Preço por MWh', sortableColumn: false, noWrap: true },
  { accessor: 'totalPrice', header: 'Valor total', sortableColumn: false, noWrap: true },
  { accessor: 'paymentDeadline', header: 'Data de pagamento', sortableColumn: false, noWrap: true },
  { accessor: 'tusdRefund', header: 'Valor de Ressarcimento (TUSD)', sortableColumn: false },
  { accessor: 'deadline', header: 'Validade da porposta', sortableColumn: false, noWrap: true },
];
