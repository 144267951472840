import { $dayjs } from '@utils/dayjs';

import { EnergyTypeEnum, SubmarketEnum } from '@utils/translators';
import { McpProposal } from '@hooks/get-mcp-proposal-by-id/types';

export interface ProposalInfoParsed {
  id: string;
  deadline: string;
  volume: number;
  supply: string;
  submarket: SubmarketEnum;
  energyType: EnergyTypeEnum;
  transactionType: 'SALE' | 'PURCHASE';
  bids: McpProposal['mcpBids'];
  trader: McpProposal['trader'];
  group: McpProposal['group'];
}

const parseProposalInfo = (proposal: McpProposal): ProposalInfoParsed => ({
  id: proposal.id,
  deadline: $dayjs(proposal.deadline).format('DD/MM/YYYY [às] HH:mm[h]'),
  volume: proposal.volume,
  supply: $dayjs(proposal.period, 'YYYY-MM-DD').format('MMMM/YYYY'),
  submarket: SubmarketEnum[proposal.submarket],
  energyType: EnergyTypeEnum[proposal.energyType],
  transactionType: proposal.transactionType,
  bids: proposal.mcpBids ?? [],
  trader: proposal.trader,
  group: proposal.group,
});

export default parseProposalInfo;
