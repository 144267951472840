import { Bid } from '@hooks/get-mcp-proposal-by-id/types';
import TableComponent from '@components/atoms/table';
import Text from '@components/atoms/text';
import ListSkeleton from '../skeleton/list-skeleton';
import { proposalDetailTableColumn } from './helper';
import { BidParsed, bidParser } from './parser';

interface ProposalDetailTable {
  suppliersData: Bid[];
  isLoading: boolean;
}

const ProposalDetailTable = ({ suppliersData, isLoading }: ProposalDetailTable) => {
  const tableValue = bidParser(suppliersData);

  return (
    <div className="flex justify-center overflow-y-auto mb-1">
      {isLoading ? (
        <ListSkeleton height="2rem" />
      ) : (
        <div className="w-full bg-zinc-50 mt-10 p-6 rounded-medium overflow-x-auto">
          <div className="flex w-full mb-6">
            <Text className="font-bold text-xl whitespace-nowrap">Detalhamento de todas as propostas</Text>
          </div>
          <TableComponent<BidParsed> data={tableValue} tableColumns={proposalDetailTableColumn} />
        </div>
      )}
    </div>
  );
};

export default ProposalDetailTable;
