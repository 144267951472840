import { useLazyQuery } from '@apollo/client';

import { McpProposal } from '@hooks/get-mcp-proposal-by-id/types';
import GET_MCP_PROPOSAL_BY_ID_FOR_SAVINGS from './query';
import { McpProposalByIdForSavings } from './types';
import parseProposalInfo from './parser';

const useProposalByIdForCompiled = () => {
  const [getProposalForCompiled, { data, error, loading }] = useLazyQuery<McpProposalByIdForSavings>(
    GET_MCP_PROPOSAL_BY_ID_FOR_SAVINGS,
  );

  const dataParsed = data ? parseProposalInfo(data?.mcpProposalByIdForSavings as unknown as McpProposal) : null;

  return {
    getProposalForCompiled,
    rawData: data?.mcpProposalByIdForSavings,
    data: dataParsed || null,
    error,
    loading,
  };
};

export default useProposalByIdForCompiled;
