import numeral from 'numeral';
import { formatDateFromString, isPassedTime, nearExpirationDate } from '@utils/dayjs';
import { bidInfo } from '@pages/mcp-compiled/helper';
import { Bid } from '@hooks/get-mcp-proposal-by-id/types';
import { MainInfoItem } from '@components/organisms/proposal-drawer-content';

export const parsedConsts = (supplier: Bid, proposalInfo: Omit<MainInfoItem, 'id'>) => {
  return {
    formatedSpreadWithCommission: numeral(supplier.spreadWithCommission).format('$ 0,0.00'),
    formattedDeadline: formatDateFromString(supplier.deadline, 'YYYY-MM-DD HH:mm:ss'),
    bidInfoNugget: bidInfo(supplier, proposalInfo.energyType.content),
    supplierLogo: supplier.trader.documents[0].uri,
  };
};

export const checkDeadline = (deadline: string, formattedDeadline: string) => {
  const nearExpirationDeadline = nearExpirationDate(deadline);
  const deadlinePassed = isPassedTime(formattedDeadline);

  const color = nearExpirationDeadline ? 'yellowLight' : deadlinePassed ? 'orangeLight' : 'primaryLight';

  return { color };
};
