import { Bid } from '@hooks/get-mcp-proposal-by-id/types';
import { formatDateFromString } from '@utils/dayjs';
import numeral from 'numeral';

export interface BidParsed extends Pick<Bid, 'deadline' | 'paymentDeadline' | 'tusdRefund'> {
  traderName: string;
  totalPrice: string;
  spreadWithCommission: string;
}

export const bidParser = (bids: Bid[]): BidParsed[] => {
  return bids.map((bid) => ({
    deadline: formatDateFromString(bid.deadline, 'YYYY-MM-DD HH:mm:ss'),
    paymentDeadline: `${bid.paymentDeadline}º dia útil`,
    totalPrice: numeral(bid.totalValue).format('$ 0,0.00'),
    tusdRefund: numeral(bid.tusdRefund).format('$ 0,0.00'),
    spreadWithCommission: numeral(bid.spreadWithCommission).format('$ 0,0.00'),
    traderName: bid.trader.name,
  }));
};
