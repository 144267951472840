import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useProposalByIdForCompiled from '@hooks/get-mcp-proposal-id-for-compiled/useMcpProposalByIdForCompiled';
import { Layout } from '@components/layout';
import ButtonComponent from '@components/atoms/button';
import Text from '@components/atoms/text';
import QuoteScopeModal from '@components/molecules/modal-quotation-scope';
import NewClarkeLogo from '@assets/new-clarke-logo.svg';
import { cardGridResponsivenessValues, mainInfo } from './helper';
import SupplierCard from '@components/molecules/card-best-proposals';
import ProposalDetailTable from '@components/molecules/proposal-detail-table';
import LoadingPage from '@components/templates/loading-page';

const CompiledProposalsPage = () => {
  const [searchParams] = useSearchParams();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isOpenScopeQuotation, setIsOpenScopeQuotation] = useState(false);
  const { getProposalForCompiled, data: proposalData, loading } = useProposalByIdForCompiled();

  const proposalId = searchParams.get('proposta');
  const publicToken = searchParams.get('token');

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    if (proposalId) {
      getProposalForCompiled({ variables: { id: proposalId, accessToken: publicToken } });
    }
  }, [proposalId, getProposalForCompiled]);

  if (!proposalData) return <LoadingPage />;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _, ...proposalInfo } = mainInfo(proposalData);
  const { bids: suppliersData } = proposalData;
  const topSuppliers = suppliersData.slice(0, 3);
  const cardGridCssValues = cardGridResponsivenessValues(topSuppliers.length);
  const highestScore = Math.max(...topSuppliers.map((s) => s.trader.score || 0));

  return (
    <Layout.Content>
      <div className="flex flex-col mx-auto max-w-7xl">
        <div>
          <img src={NewClarkeLogo} alt="Clarke-logo" className="mb-8" />
          <Text className="text-[#008C4C]">{proposalData.group.name}</Text>
        </div>

        <div className="flex flex-col sm:flex-row gap-6 sm:gap-0 justify-center md:justify-between">
          <h1 className="text-[#008C4C] font-bold text-4xl">Mercado de Curto Prazo - {proposalData.supply}</h1>
          <ButtonComponent
            kind="primary"
            size="1"
            label="Ver informações do escopo"
            icon="EyeIcon"
            iconPosition="left"
            type="button"
            onClick={() => setIsOpenScopeQuotation(true)}
            screen={isSmallScreen ? 'mobile' : 'desktop'}
            className="whitespace-nowrap"
          />
          <QuoteScopeModal
            proposalInfo={proposalInfo}
            isOpen={isOpenScopeQuotation}
            setIsOpen={setIsOpenScopeQuotation}
          />
        </div>

        <section className="flex w-full justify-center">
          <div className={cardGridCssValues}>
            {topSuppliers.map((supplier, idx) => (
              <div
                key={`card-${idx}`}
                className={`flex min-w-[350px] justify-center ${idx === 2 ? 'col-span-1 md:col-span-2 xl:col-span-1' : ''}`}
              >
                <SupplierCard
                  supplier={supplier}
                  supplierLength={topSuppliers.length}
                  highestScore={highestScore}
                  proposalInfo={proposalInfo}
                  position={idx + 1}
                />
              </div>
            ))}
          </div>
        </section>

        <section>
          <ProposalDetailTable suppliersData={suppliersData} isLoading={loading} />
        </section>
      </div>
    </Layout.Content>
  );
};

export default CompiledProposalsPage;
