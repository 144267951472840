import Text from '@components/atoms/text';

const Badge = ({ color, label }: { color: string; label: string }) => (
  <div
    className="flex h-9 px-4 py-2 mb-3 rounded-small w-full items-center justify-center"
    style={{ backgroundColor: color }}
  >
    <Text className="w-auto h-auto font-bold text-sm whitespace-nowrap">{label}</Text>
  </div>
);

export default Badge;
