import TagComponent from '@components/atoms/tag';
import Text from '@components/atoms/text';
import { TagColor } from '@components/atoms/tag';

interface SupplierHeader {
  logo: string;
  name: string;
  score: number;
  position: number;
}

const SupplierHeader = ({ logo, name, score, position }: SupplierHeader) => {
  const positionTrophyColor: TagColor[] = ['primaryLight', 'default', 'yellowLight'];

  return (
    <div className="flex items-center p-4 bg-[#F2F2F2] rounded-medium">
      <div className="h-14 w-14 rounded-small overflow-hidden">
        <img src={logo} alt="Supplier-logo" className="h-full w-full bg-white object-contain p-1" />
      </div>
      <Text className="pl-4">{name}</Text>
      <div className="flex ml-auto gap-3">
        <TagComponent
          color={`${positionTrophyColor[position - 1]}` || 'ghost'}
          kind="icon"
          icon="TrophyIcon"
          label={`${position}º`}
        />
        <TagComponent color="default" kind="icon" icon="StarIcon" label={`${score ?? '-'}`} />
      </div>
    </div>
  );
};

export default SupplierHeader;
