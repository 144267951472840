import { IconName } from '@components/atoms/hero-icon';
import ModalComponent from '@components/atoms/modal';
import { NuggetInfoComponent } from '@components/atoms/nugget-info';
import NuggetIconsComponent from '@components/atoms/nugget-info/nugget-icons';
import { MainInfoItem } from '@components/organisms/proposal-drawer-content';
import TreeIcon from '@assets/tree-icon-green.svg';

interface QuoteScopeModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  proposalInfo: Omit<MainInfoItem, 'id'>;
}

const QuoteScopeModal = ({ isOpen, setIsOpen, proposalInfo }: QuoteScopeModal) => {
  return (
    <ModalComponent
      title="Escopo da cotação"
      description=""
      modalIcon={false}
      isOpen={isOpen}
      setOpen={setIsOpen}
      className="md:w-[472px]"
    >
      <div className="grid grid-cols-1 md:grid-cols-2">
        {Object.keys(proposalInfo).map((key, idx) => {
          const shoulDisplayImgPath = proposalInfo[key as keyof typeof proposalInfo].title === 'Tipo de energia';

          return (
            <NuggetInfoComponent
              key={`main-info-${idx}`}
              title={{
                value: proposalInfo[key as keyof typeof proposalInfo].title,
                weight: 'normal',
                size: 'small',
              }}
              content={{
                value: proposalInfo[key as keyof typeof proposalInfo].content,
                size: 'medium',
              }}
              icon={
                <NuggetIconsComponent
                  iconName={
                    !shoulDisplayImgPath
                      ? (proposalInfo[key as keyof typeof proposalInfo].iconName as IconName)
                      : undefined
                  }
                  iconPath={shoulDisplayImgPath ? TreeIcon : undefined}
                  extraClassNames="bg-brand-primary-10 rounded-small p-2 text-brand-primary-60"
                />
              }
            />
          );
        })}
      </div>
    </ModalComponent>
  );
};

export default QuoteScopeModal;
