import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import { COMPILED_MCP_PROPOSALS } from '@routers/constants';

import TreeIcon from '@assets/tree-icon-green.svg';

import Text from '@components/atoms/text';
import { NuggetInfoComponent } from '@components/atoms/nugget-info';
import { MainInfoItem } from '@components/organisms/proposal-drawer-content';
import TagComponent from '@components/atoms/tag';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button';
import { IconName } from '@components/atoms/hero-icon';
import NuggetIconsComponent from '@components/atoms/nugget-info/nugget-icons';
import { usePublicToken } from '@hooks/generate-public-token/usePublicToken';
import { isPassedTime } from '@utils/dayjs';

import NuggetButtons, { NuggetButtonsProps } from '../nugget-buttons';
import style from './style.module.css';

interface MainContentDrawerProps {
  title: string;
  mainInfo: MainInfoItem;
  bids: Record<string, NuggetButtonsProps['infos']>;
  nuggetButtons: {
    onClick: (selectedItem: string) => void;
    mainBidButtons: ButtonComponentProps[];
    otherBidsButtons: ButtonComponentProps[];
  };
  winnerBid?: NuggetButtonsProps['infos'];
}

const MainContentDrawer: React.FC<MainContentDrawerProps> = ({
  title,
  mainInfo,
  bids,
  nuggetButtons: { mainBidButtons, otherBidsButtons, onClick },
  winnerBid,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { id, ...proposalInfo } = mainInfo;
  const { createNewToken } = usePublicToken();

  const handleNuggetButton = (currentItem: number) => {
    if (winnerBid) return [];

    if (currentItem === 0) return mainBidButtons;

    return otherBidsButtons;
  };

  const bidsNuggetButtons = () => {
    if (winnerBid) {
      const updatedNuggetButtons = { ...bids };
      delete updatedNuggetButtons[winnerBid[0]?.id as string];
      return updatedNuggetButtons;
    }

    return bids;
  };

  const bidsInfo = Object.keys(bidsNuggetButtons());

  const handleClickNavigateToCompiled = async () => {
    const { data } = await createNewToken({ variables: { id: id } });
    setLoading(true);
    const token = data?.createProposalAccessTokenForMcp;

    if (token) {
      setLoading(false);
      navigate(`${COMPILED_MCP_PROPOSALS}?proposta=${id}&token=${token}`);
    }
  };

  return (
    <>
      <div className="flex justify-between flex-col lg:flex-row">
        <Text size="text-heading-medium" weight="font-bold">
          {title}
        </Text>
        <ButtonComponent
          kind="primary"
          size="1"
          label="Gerar compilado"
          icon="ArrowTopRightOnSquareIcon"
          iconPosition="right"
          type="button"
          loading={loading}
          onClick={handleClickNavigateToCompiled}
          className="whitespace-nowrap mt-2 lg:mt-0"
        />
      </div>
      <div className={style.mainNuggetContainer}>
        {Object.keys(proposalInfo).map((key, idx) => {
          const shoulDisplayImgPath = proposalInfo[key as keyof typeof proposalInfo].title === 'Tipo de energia';

          return (
            <NuggetInfoComponent
              key={`main-info-${idx}`}
              title={{
                value: proposalInfo[key as keyof typeof proposalInfo].title,
                weight: 'bold',
                size: 'small',
              }}
              content={{
                value: proposalInfo[key as keyof typeof proposalInfo].content,
                size: 'medium',
              }}
              icon={
                <NuggetIconsComponent
                  iconName={
                    !shoulDisplayImgPath
                      ? (proposalInfo[key as keyof typeof proposalInfo].iconName as IconName)
                      : undefined
                  }
                  iconPath={shoulDisplayImgPath ? TreeIcon : undefined}
                  extraClassNames="bg-brand-primary-10 rounded-small p-2 text-brand-primary-60"
                />
              }
            />
          );
        })}
      </div>
      {winnerBid && (
        <div className={twMerge(style.paddingTop, bidsInfo.length > 0 ? style.borderBotom : '')}>
          <Text size="text-paragraph-medium" color="text-brand-primary-70" weight="font-bold">
            Proposta Vencedora
          </Text>

          <NuggetButtons
            infos={winnerBid}
            buttons={[
              {
                label: 'Proposta aceita',
                icon: 'CheckCircleIcon',
                iconPosition: 'left',
                disabled: true,
                kind: 'primary',
                size: '1',
              },
            ]}
          />
        </div>
      )}

      {bidsInfo.length > 0 && (
        <div className={style.paddingTop}>
          <Text
            size="text-heading-xSmall"
            color={winnerBid ? 'text-brand-gray-80' : 'text-brand-primary-70'}
            weight="font-bold"
          >
            {winnerBid ? 'Outras propostas' : 'Todas as propostas'}
          </Text>

          {Object.keys(bidsNuggetButtons()).map((keyId, idx) => (
            <div onClick={() => onClick(keyId)} key={keyId} className={style.borderBotom}>
              <div className="flex gap-2">
                <TagComponent color="default" label={`${bids[keyId][0].position}º lugar`} size="1" />
                {bids[keyId][0]?.deadline && isPassedTime(bids[keyId][0]?.deadline ?? '') && (
                  <TagComponent color="orangeLight" label="Proposta vencida" size="1" />
                )}
              </div>
              <NuggetButtons infos={bids[keyId]} buttons={handleNuggetButton(idx)} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MainContentDrawer;
