import { NuggetInfoComponent } from '@components/atoms/nugget-info';
import NuggetIconsComponent from '@components/atoms/nugget-info/nugget-icons';
import TagComponent, { TagColor } from '@components/atoms/tag';
import Text from '@components/atoms/text';
import { Bid } from '@hooks/get-mcp-proposal-by-id/types';
import { MainInfoItem } from '@components/organisms/proposal-drawer-content';
import Badge from './badge';
import SupplierHeader from './supplier-header';
import { checkDeadline, parsedConsts } from './helper';

interface SupplierCard {
  supplier: Bid;
  supplierLength: number;
  highestScore: number;
  proposalInfo: Omit<MainInfoItem, 'id'>;
  position: number;
}

const SupplierCard = ({ supplier, supplierLength, highestScore, proposalInfo, position }: SupplierCard) => {
  const { bidInfoNugget, formatedSpreadWithCommission, formattedDeadline, supplierLogo } = parsedConsts(
    supplier,
    proposalInfo,
  );

  const { color: tagColor } = checkDeadline(supplier.deadline, formattedDeadline);

  return (
    <div className="w-[406px] mt-auto p-6 bg-zinc-50 rounded-medium">
      <header>
        {supplierLength > 1 && supplier.bestOffer && <Badge color="#55EC8F" label="Melhor preço" />}

        {supplierLength > 1 && supplier.trader.score === highestScore && (
          <Badge color="#CFDFFC" label="Melhor avaliação" />
        )}

        <SupplierHeader
          logo={supplierLogo}
          name={supplier.trader.name}
          score={supplier.trader.score}
          position={position}
        />
      </header>

      <div className="flex justify-between mt-6 mb-2">
        <Text>Preço</Text>

        <TagComponent
          color={tagColor as TagColor}
          kind="icon"
          icon="CalendarIcon"
          label={formattedDeadline ?? '-'}
          size="1"
        />
      </div>

      <Text className="font-bold text-3xl">PLD + {formatedSpreadWithCommission}</Text>

      <div className="grid grid-cols-2">
        {Object.keys(bidInfoNugget)
          .slice(1)
          .map((key, idx) => {
            const nugget = bidInfoNugget[key as keyof typeof bidInfoNugget];
            if (typeof nugget === 'object' && 'title' in nugget) {
              return (
                <NuggetInfoComponent
                  key={idx}
                  title={{
                    value: nugget.title,
                    weight: 'normal',
                    size: 'small',
                  }}
                  content={{
                    value: nugget.content,
                    size: 'medium',
                  }}
                  icon={
                    <NuggetIconsComponent
                      iconName={nugget.iconName}
                      extraClassNames="bg-brand-primary-10 rounded-small p-2 text-brand-primary-60 "
                    />
                  }
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default SupplierCard;
